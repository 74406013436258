function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { createRef, warn } from '@algolia/autocomplete-shared';
import { createStorageApi } from './createStorageApi';
import { getTemplates } from './getTemplates';

function getDefaultSubscribe(store) {
  return function subscribe(_ref) {
    var onSelect = _ref.onSelect;
    onSelect(function (_ref2) {
      var item = _ref2.item,
          state = _ref2.state,
          source = _ref2.source;
      var inputValue = source.getItemInputValue({
        item: item,
        state: state
      });

      if (source.sourceId === 'querySuggestionsPlugin' && inputValue) {
        var recentItem = {
          id: inputValue,
          label: inputValue,
          category: item.__autocomplete_qsCategory
        };
        store.addItem(recentItem);
      }
    });
  };
}

export function createRecentSearchesPlugin(options) {
  var _getOptions = getOptions(options),
      storage = _getOptions.storage,
      transformSource = _getOptions.transformSource,
      subscribe = _getOptions.subscribe;

  var store = createStorageApi(storage);
  var lastItemsRef = createRef([]);
  return {
    name: 'aa.recentSearchesPlugin',
    subscribe: subscribe !== null && subscribe !== void 0 ? subscribe : getDefaultSubscribe(store),
    onSubmit: function onSubmit(_ref3) {
      var state = _ref3.state;
      var query = state.query;

      if (query) {
        var recentItem = {
          id: query,
          label: query
        };
        store.addItem(recentItem);
      }
    },
    getSources: function getSources(_ref4) {
      var query = _ref4.query,
          setQuery = _ref4.setQuery,
          refresh = _ref4.refresh,
          state = _ref4.state;
      lastItemsRef.current = store.getAll(query);

      function onRemove(id) {
        store.removeItem(id);
        refresh();
      }

      function onTapAhead(item) {
        setQuery(item.label);
        refresh();
      }

      return Promise.resolve(lastItemsRef.current).then(function (items) {
        if (items.length === 0) {
          return [];
        }

        return [transformSource({
          source: {
            sourceId: 'recentSearchesPlugin',
            getItemInputValue: function getItemInputValue(_ref5) {
              var item = _ref5.item;
              return item.label;
            },
            getItems: function getItems() {
              return items;
            },
            templates: getTemplates({
              onRemove: onRemove,
              onTapAhead: onTapAhead
            })
          },
          onRemove: onRemove,
          onTapAhead: onTapAhead,
          state: state
        })];
      });
    },
    data: _objectSpread(_objectSpread({}, store), {}, {
      // @ts-ignore SearchOptions `facetFilters` is ReadonlyArray
      getAlgoliaSearchParams: function getAlgoliaSearchParams() {
        var _params$facetFilters, _params$hitsPerPage;

        var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        // If the items returned by `store.getAll` are contained in a Promise,
        // we cannot provide the search params in time when this function is called
        // because we need to resolve the promise before getting the value.
        if (!Array.isArray(lastItemsRef.current)) {
          process.env.NODE_ENV !== 'production' ? warn(false, 'The `getAlgoliaSearchParams` function is not supported with storages that return promises in `getAll`.') : void 0;
          return params;
        }

        return _objectSpread(_objectSpread({}, params), {}, {
          facetFilters: [].concat(_toConsumableArray((_params$facetFilters = params.facetFilters) !== null && _params$facetFilters !== void 0 ? _params$facetFilters : []), _toConsumableArray(lastItemsRef.current.map(function (item) {
            return ["objectID:-".concat(item.label)];
          }))),
          hitsPerPage: Math.max(1, ((_params$hitsPerPage = params.hitsPerPage) !== null && _params$hitsPerPage !== void 0 ? _params$hitsPerPage : 10) - lastItemsRef.current.length)
        });
      }
    }),
    __autocomplete_pluginOptions: options
  };
}

function getOptions(options) {
  return _objectSpread({
    transformSource: function transformSource(_ref6) {
      var source = _ref6.source;
      return source;
    }
  }, options);
}